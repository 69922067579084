import React, { Component } from 'react';
import axios from 'axios';
import Contestant from './Contestant';

class ListContestants extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contestants : []
        }
    }

  componentDidMount () {
        axios.get('/api/contestants')
          .then(response => {
                this.setState({contestants: response.data})
              console.log(response.data);
          })
    } 
     
    render () {
        return (
            <div>
                {this.state.contestants.map(con => 
                    <Contestant key={con.id} name={con.firstname} />    
                )}
            </div>
        );
    }
} 

export default ListContestants;