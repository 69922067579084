import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import ListContestants2  from './components/vote/ListContestants2';
import ListContestants  from './components/vote/ListContestants';

import './custom.css'
import './css/style/open-iconic-bootstrap.min.css';
    // import './css/style/animate.css';
    // import './css/style/owl.carousel.min.css';
    // import './css/style/owl.theme.default.min.css';
    // import './css/style/magnific-popup.css';
    // import './css/style/aos.css';
    // import './css/style/ionicons.min.css';
    // import './css/style/bootstrap-datepicker.css';
    // import './css/style/jquery.timepicker.css';
    // import './css/style/flaticon.css';
    // import './css/style/icomoon.css';
import './css/style/style.css';

export default class App extends Component {
  static displayName = App.name;
  

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        {/* <Route exact path="/" component={() => (window.location = "/index.html")} />  */}
        <Route path='/counter' component={Counter} />
        <Route path='/fetch-data' component={FetchData} />
        <Route path='/listcontestants2' component={ListContestants2} />
        <Route path='/listcontestants' component={ListContestants} />
      </Layout>
    );
  }
}
