import React from 'react';

const contestant = (props) => {
return  (
     <div>
        <img src={props.photo} width="162px" />
        {props.firstname} {props.lastname}
    </div>
 )
}

export default contestant;