import React, {useState, useEffect, Component} from 'react';
import axios from 'axios';
import Contestant from './Contestant';
import VoteResults from './VoteResults';
import '../../bs/bootstrap/css/bootstrap.min.css';
import '../../css/Register.css';
//import '../../bs/js/jquery.min.js';
//import '../../bs/bootstrap/js/bootstrap.min.js';

const ListContestants = () => {
   const [contestantState,  setContestantState] = useState([{}])
   const [IDState, setIDState] = useState(null)
   const [resultsState, setResultsState] = useState('No')   
   const [emailState, setEmailState] = useState({})
   

 useEffect(() => {
    axios.get('/api/contestants/')
        .then(response => {
            setContestantState(response.data);
        });
   }, []);

const voteMe = (id) => {
    if (emailState.email == null) {
        alert('Please enter your Email Address');
        return;
    }
    //event.preventDefault();
    //https://hunter.io/api-documentation/v2
    // result returns the main status of the verification. It can take 3 possible values:
    // "deliverable": the email verification is successful and the email address is valid.
    // "undeliverable": the email address is not valid.
    // "risky": the verification can't be validated.
    console.log(emailState.email);
    //axios.get('https://api.hunter.io/v2/email-verifier?api_key=3cf5ac98e3eb36aa334d38dc6fd3efa70e9d5856&email=' + emailState.email)
     //  .then(res => {
     //      console.log(res.data.data["result"]);
     //      var verifyEmail = res.data.data.result;      
     //      if (verifyEmail=='deliverable' || verifyEmail=='risky') {
                   axios.put('/api/contestants/' + id)
                   .then(response => {
                        axios.get('/api/contestants/')
                        .then(response => {
                            setContestantState(response.data);
                            setIDState(null);
                            setResultsState('Yes');
                            //setResultsState(prevState => prevState || !prevState);                            
                        });
                        //save Email address
                        axios.post('/api/contestants', { email: emailState.email })
                           .then(response => {
                            setEmailState('');
                           });
                   });
         //  }
       //})
   }

const showEmail = (id) => {
    setIDState(id);    
    setResultsState('No');
}   

const updateField = e => {
    setEmailState({
    ...emailState,
    [e.target.name]: e.target.value
    });
};

const closeMe = () => {
    setResultsState('No');
    setIDState(null);
}

    return (
        <div className="container">
            {contestantState.map(con => 
                <div className="row" key={con.id} style={{paddingBottom:"20px"}}>
                    <div className="col-md-4"><img src={con.photo} width="162px" /></div>
                    <div className="col-md-4"><h4>{con.firstname} {con.lastname}</h4></div>
                    <div className="col-md-4"><input type='button' className='btn btn-primary px-5 py-2' value='Vote' onClick={() => showEmail(con.id)} /></div>
                </div>   
            )}  
               
             {/* <table>
                 <tbody>
                    {contestantState.map(con => 
                        <tr key={con.id}>
                            <td><img src={con.photo} width="162px" /></td>
                            <td>{con.firstname} {con.lastname}<input type='button' className='btn btn-primary px-5 py-2' value='Vote' onClick={() => showEmail(con.id)} /> </td>
                        </tr>
                    )}  
                </tbody>
             </table>
             <ul>
             {contestantState.map(con => 
                <li><Contestant key={con.id} firstname={con.firstname} lastname={con.lastname} photo={con.photo} /><input type='button' className='btn btn-primary px-5 py-2' value='Vote' onClick={() => showEmail(con.id)}  />                   
                </li>
             )}
             </ul> */}
             
                    { IDState > 0  ?                    
                      <div className="popUpBox"><form><br></br><br></br>Enter your email address: <input type='email' name='email' onChange={updateField} required /> <input type='button' value='Submit' onClick={() => voteMe(IDState)}  /><br></br><br></br><br></br><br></br> <center><input type='button' value='Close' onClick={() => closeMe()}  /></center></form></div> : null
                     }
                    <br></br><br></br>
                    {
                        resultsState == 'Yes' ?
                        <div className="popUpBox">
                            <center><h3>Voting Results</h3></center>
                            <ul>
                            {contestantState.map(con => 
                                <li><VoteResults key={con.id} name={con.firstname} vote={con.vote} />                   
                                </li>
                            )}
                            </ul>
                            <center><input type='button' value='Close' onClick={() => closeMe()}  /></center>
                        </div>
                        : null
                    }
         </div>
     )
}

export default ListContestants;